@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.image_banner_container {
    position: relative;
    width:100%;
  }
  
  .image_banner_image {
    height: auto;
    width: 100%;
    /*padding-bottom: 56.25%; /* 16:9 aspect ratio (h/w * 100) */
    background-size: cover;
  
  }
  
  .image_banner_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 100;
    position: absolute;
    transition: opacity 1.5s ease_in_out;
    color: aqua;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    color: #F1F1F1;
    font-size: 65px;
    line-height: 80px;

  }
  
  .fade_in {
    opacity: 1;
  }
  
  .fade_out {
    opacity: 0;
  }

  .mobile_banner {
    display:none;
  }

  @media all and (max-width: 800px) {
    .mobile_banner {
      display: flex;
    }

    .desktop_banner {
      display: none;
    }

    .image_banner_text {
      font-size: 48px;
    }
  }


  