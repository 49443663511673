.mobile_footer {
    background-color: #042A2A;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* padding-bottom: 40px; */
    height: 50px;
}



@media screen and (min-width: 450px) {
    /* For mobile phones: */
    .mobile_footer {
      display: none;
    }
  }

.footer_icons {
    height:40px;
}

.logo {
    align-self: center;
}

.contact_icon {
    height: 48px;
    bottom: 25px;
    position: fixed;
    left: calc(50% - 24px);
}

.login_icon {
    align-self: center;
}