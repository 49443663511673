.page_heading {
    flex-basis: 100%;
    justify-content: center;
    flex-grow: 1;
    display: flex;
}

.feature_block {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:20px;
}

.feature_box {
    flex-basis: 50%;
    /* margin-top: 40px; */
}

.feature_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1px;

    color: #152D35;
}

.feature_description {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* or 233% */

    letter-spacing: 0.2px;

    color: #3D3E53;
    height: fit-content;
}

.analysis_text {
    padding-left: 80px;
    margin-bottom: 40px;
}


.analysis_block {
    margin-bottom: 40px;
}


.demo_button {
    background-color: #008080;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-top: 60px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
  }
  
.demo_button:hover {
background-color: #2c5a4a;
}

.feature_image {

    /* flex-basis: 60%; */
    align-self: center;
}

.feature_image_style {
    /* width: 100% */
}

div#automate_reports {
    margin-top: 100px;
}

div#automate_reports > .feature-image {

    padding-left: 4%;
    flex-basis: 42%;
}

div#data_in_one > .feature_box {

    padding-left: 8%;
}


@media screen and (max-width: 800px) {
    /* For mobile phones: */
    .feature_box {
        flex-basis: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    img {
      width: 100%;
    }

    .feature_description {

      }

    .centralize_data_top_image {
        display: none;
    }

    .demo_button {
        margin-top:20px;
    }

    .feature_image {
        flex-basis: 90%;
    }

    .analysis_text {
        padding-left: 0px;
    }

    .feature_block {
        margin-top: 0px;
    }

    .last_feature {
        margin-bottom: 70px;
    }

    .feature_description {
        margin-bottom: 20px;
        font-size: 14px;
        color: #636870;
    }

    .page_heading {
        margin-bottom: 0px;
        margin-top: 40px;
    }
  }

  @media screen and (min-width: 800px) {
    /* For mobile phones: */
    
    .centralize_data_bottom_image {
        display: none;
    }
  }


