.page_heading {
    flex-basis: 100%;
    justify-content: center;
    flex-grow: 1;
    display: flex;
}

/* .feature-block {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
} */

.feature_box {
    display: flex;
    flex-basis: 90%;
    /* flex-grow: 1; */
    flex-wrap: wrap;
    justify-content: center;
}

/* .feature-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* or 160% 

    display: flex;
    align-items: center;
    letter-spacing: 0.1px;

    color: #152D35;
} */

.feature_description {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* or 233% */

    letter-spacing: 0.2px;

    color: #3D3E53;
    height: 94px;
    margin-top: 20px;
}

.feature_image {

    /* flex-basis: 100%;
    flex-grow: 1;
    padding-left: 4%;
    flex-basis: 42%; */
    margin-bottom: 10px;
}



@media screen and (max-width: 800px) {
    /* For mobile phones: */
    .feature_description {
        height:auto;
        margin-bottom: 80px;
        font-size: 14px;
        color: #636870;
    }

    .page_heading {
        margin-bottom: 0px;
    }
}