.mobile_menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 80%;
    background: #F6F6F6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    z-index: 200;
}

.close_container {
    flex: 1 1 100%;
    height: 60px;
}

.close_button {
    position: absolute;
    /* left: 80.83%;
    right: 12.22%;
    top: 6.88%;
    bottom: 91.38%; */
    height: 14px;
    width: 25px;
    flex: 1 1 auto;
    right: 50px;
    top:40px;
}

.menu_container {
    display: flex;
    justify-content: center;
    top: 15%;
    position: absolute;
}

.central_container {
    width: 70%; 
    display: flex;
    flex-wrap: wrap;
    /* margin-left: 5%; */
}

.cq_brand {

    display: flex;
    flex-basis: 100%;
    justify-content: center;
    /* border-bottom: 1px solid #E6E6E6;; */
    /* margin-bottom: 5%; */
}

.cq_logo {

    height: 39px;
    width: 35px;
    align-self: center;
}

.cq_company_name {

    /* width: 207px; */
    height: 31px;

    display: flex;
    align-items: center;
    margin-left: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #252B42;
}

.home_link {
    text-decoration: none;
    width: 100%;
}

.menu_items {

    display: flex;
    flex-wrap: wrap;
    margin-top: 15%;
    /* height: 225px; */
}

.product {


    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    flex: 1 1 100%;
    text-align: left;
    padding-left: 15%;
    display: flex;
    flex-wrap: wrap;
    /* height: 72px; */
}

.title_container {

    display: flex;
    justify-content: center;
    align-self: center;
    height:72px;
}

.products_menu_title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    /* or 125% */

    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;

    color: #636870;
    padding-top: 10px;
    padding-bottom: 10px;
    width: fit-content;
    text-decoration: none;    
    flex-basis: 100%;
    align-self: center;
}

.down_arrow {
    margin-left: 5px;
    height: 15px;
    width: 15px;
    align-self: center;
}

.hide_element {
    display: none;
}

.product_menu {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* justify-content: center; */
}

.hide_element {
    display: none;
}

.product_menu_item {

    /* flex: 1 1 100%; */
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* or 179% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;

    color: #808192;
    border-top: 1px solid #E6E6E6;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-basis: 100%;

    text-decoration: none;
}

.menu_item {

    flex: 1 1 100%;
    border-bottom: 1px solid #E6E6E6;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;

    padding-top: 23px;
    padding-bottom: 20px;
    /* or 125% */

    /* display: flex; */
    align-items: center;
    text-align: left;
    letter-spacing: 0.2px;

    color: #636870;
    text-decoration: none;
    /* padding-left: 24px; */
    padding-left: 15%;

}

@media screen and (min-width: 450px) {
    /* For mobile phones: */
    .mobile_menu {
      display: none;
    }
  }
