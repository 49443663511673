.footer {
    background-color: #042A2A;
    color: #fff;
    font-size: 14px;
    padding: 30px 0;
    display: flex;
    height:150px;
    /* padding: 10px 20px; */ 
    /* position: absolute;
    top: calc(100% - 210px);
    width: 100%; */
}



/* @media screen and (max-width: 450px) {
    [class="footer"] {
      display: none;
    }
  } */
  
.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* max-width: 1000px; */
    margin: 0 auto;
    padding: 0 20px;
    /* width: 100%; */
    justify-content: center;
    flex-basis: 100%
}

@media screen and (min-width: 1280px) {
    /* For mobile phones: */
    [class="footer-container"] {
      flex-basis: 1265px;
    }
  }

.logo img {
    height: 50px;
    /* margin-right: 20px; */
}

.footer-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    align-content: baseline;
    flex-grow: 1;
    flex-basis: fit-content;
}

.footer-columns h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #E6E6E6;
}

.footer-columns ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* or 240% */
    letter-spacing: 0.2px;

    color: #E6E6E6;
    flex-grow: 1;
    flex-basis: fit-content;
}

/* .footer-column ul li {
    margin-bottom: 10px;
} */

.footer-columns ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-columns ul li a:hover {
    text-decoration: underline;
}



@media screen and (max-width: 800px) {
    /* For mobile phones: */
    .footer-columns li {
        font-weight: 500;
        font-size: 7px;
        line-height: 16px;
        /* or 229% */
        
        letter-spacing: 0.2px;
        
    }

    .footer-columns {
        margin-top: 0px;
        margin-bottom: 0px;
        align-content: stretch;
        align-items: center;
    }

    .footer-columns h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 24px;
        /* or 240% */

        letter-spacing: 0.1px;
    }

    .logo {
        display: none;
        /* margin-right: 20px; */
    }

    .footer {
        position: relative;
        margin-bottom: 50px;
        padding-top: 10px;
    }


    .footer-columns ul {
        text-align: center;
    }
  }
