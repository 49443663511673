.nav-outer {
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  flex-wrap: wrap;
  position: sticky;
  top: 0px;
  z-index: 150;
}

@media screen and (max-width: 450px) {
  /* For mobile phones: */
  [class="nav-outer"] {
    display: none;
  }
}

.navbar {
  /* background-color: #0f0e17; */
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 90%;
  height: 35px;
}

@media screen and (min-width: 1920px) {
  /* For mobile phones: */
  [class="navbar"] {
    flex-basis: 1859px;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-brand img {
  margin-right: 4px;
}

.navbar-brand h1 {
  margin: 0;
  font-family: 'Nunito';

  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #252B42;
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.products_dropdown {

  z-index: 100;
  position: absolute;
  top: 41px;
  list-style-type: none;
  padding: 0px;
  background: #F1F1F1;
  width: 200px;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 5px;
  display: none;
}

.product_header_text:hover {
  color: #042A2A; 
}

.product_dropdown_items:hover {
  background: #505050;
}

.product_dropdown_items:hover .product_dropdown_item_text{
  color: #FFFFFF;
}

.product_header:hover .product_header_text{
  color: #042A2A;
}

.product_dropdown_items:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.product_dropdown_items:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.product_header {
  display: flex;
  justify-content: center;
}

.product_header:hover > .products_dropdown {
  display: flex;
}

/* .products_dropdown:hover {
  display: flex;
} */

.product_dropdown_items {
  /* padding: 5px; */
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px; */
  /* text-decoration: none; */
  /* align-self: ; */
}

.product_dropdown_item_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  flex-basis: 100%;
  text-align: left;
  color: #313144;
  text-decoration: none;
  padding-left: 20px;
  /* top: calc(50% - 6px); */
  position: relative;
  
}

.nav-link {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #636870;

}

.nav-link:hover {
  color: #042A2A;
}

.nav-icons-container {
  display: flex;
  align-self: center;
}

.nav-icons {
  flex-basis: 35px;
  padding: 6px;
  flex-grow: 1;
}

.nav-item {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #636870;
}

.hide_element {
  display: none
}


  
.message_button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 17px 16px;
  gap: 10px;
  background: #008080;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 16px;
  /* width: 120px; */
  height: 31px;
  left: 1051px;
  top: 61px;
  background: #008080;
  border-radius: 20px;
  font-weight: bold;
  font-family: 'Montserrat';

  text-transform: capitalize;
  /* font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* display: flex; */
  /* align-items: center; 
  text-align: center;

  color: #FFFFFF; */


}

.message_link {
  text-decoration: none;
}

.button:hover {
  background-color: #2c5a4a;
}

.message_button:hover {
  background-color: #2c5a4a;
  }