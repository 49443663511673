/* Green Box */
.green_box {
    background-color: #042A2A;
    color: white;
    /* padding: 16px; */
    margin-top: 12px;
    flex-basis: 100%;
    text-align: center;
    height: 60px;
  }

  .products_label_green_box {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 57px;
    letter-spacing: 0.2px;

    color: #E6E6E6;

  }
  
  .feature_heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #152D35;
  }

  .feature_description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #3D3E53;
    margin-top: 10px;
    /* text-align: justify; */
  }

  .feature_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* max-width: 1000px; */
    /* margin-left: 108px;
    margin-right: 108px; */
    margin-top: 30px;
    flex-basis: 90%;
    /* padding-right: 50px; */
  }

  .column {
    flex-basis: calc(50% - 200px);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 100px;
    margin-right: 100px;
  }



  .servcies_cta {
    justify-content: center;
  }
  
  .button_wrapper {
    display: flex;
    /* margin: 4px 2px; */
    cursor: pointer;
    width: fit-content;
    text-decoration: none;
    flex-basis: 100%;
    margin-top: 20px;
  }
  
  .button_text {
    /* color: white; */
    gap: 10px;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;
    transition: all 0.2s ease-in-out; 
    border-radius: 5px;
    background-color: #008080;
    border: none;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;

    /* light-text-color */

    color: #FFFFFF;
  }

  .button_text:hover {
    background-color: #2c5a4a;
    }
  
  .services {
    padding: 16px;
    margin: 20px;
    /* height: 178px; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: calc(90% - 200px);
    text-align: center;
  }
  
  /* .services p {
    font-size: 1.2em;
    line-height: 1.5em;
    margin: 0;
  } */
  
  /* .services .button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  } */
  
  .contact_container {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    margin-bottom: 5px;
    flex-wrap: wrap;
    /* margin-bottom: 20px; */
  }

  @media all and (max-width: 800px) {
    /* For mobile phones: */
    .feature_row {
      flex-basis: 100%;
      /* padding-right: 50px; */
    }
  }


  @media all and (max-width: 800px) {
    .column {
      flex-basis: 90%;
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 0px;
    }

    .first_product_feature {
      margin-top: 25px;
    }
  }


  @media all and (max-width: 800px) {
    .contact_container {
      
      width: 100%;
      margin-bottom: 125px;
    
    }

    .button_wrapper {
      margin-bottom: 25px;
    }

    .feature_description {
      font-size: 14px;
      color: #636870;
    }

    .services {
      max-width: 100%;
      padding-left: 0px;

      padding-right: 0px;

    }
  
  }

  @media all and (max-width: 800px) {
    .green_box {
      margin-top: 8px;
    }
  
  }

  