.contact_form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    background-color: #FFFFFF;
}

.form_heading {
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2B2F32;
}

.contact_image {
    width: 50%;
}

.form_phone {
  height: 52px;
  border-radius: 5px;
  padding-left: 6px;
}

.input_field {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* margin-bottom: 16px; */
  padding: 0px;
  border-radius: 5px;
  
  width: calc(100% - 12px);
  height: 52px;
  padding-left: 12px;
  background-color: rgba(239, 241, 249, 0.6);
  border: none;
}

.message_field {
  height: 156px;
  resize: none;
  padding-top: 10px;
}

@media screen and (max-width: 450px) {
  /* For mobile phones: */
  [class="contact-image"] {
    width:100%;
  }
}

@media screen and (max-width: 450px) {
  /* For mobile phones: */
  [class="contact-form"] {
    flex-wrap: wrap;
  }
}


.form_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    /* margin-top: 32px; */
    /* flex-basis: 851px; */
    align-items: center;
    top: 20px;
    position: relative;
    align-self: flex-start;
    
  }
  
.field_outer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 80%;
  /* height: 52px; */
  
}

.field_outer:first-of-type {
  margin-top: 3%;
}
  
  .button[type="submit"] {
    background-color: #30695c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    gap: 10px;
    background: #008080;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-transform: capitalize;
    height:fit-content;

  
  }
  
  .button[type="submit"]:hover {
    background-color: #2c5a4a;
  }


  @media screen and (max-width: 450px) {
      /* For mobile phones: */
      .contact_image {
        display: none;
      }
    }

.thankyou_message {
  width: 50%;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #008080;
}

.hide_element {
  display: none !important;
}


@media all and (max-width: 800px) {
  .form_container {
    
    width: calc(100% - 40px);
    top: 40px;
    /* padding-right: 50px */
  }

  .thankyou_message {
    height: 200px;
    background-color: #F6F6F6;
    flex-basis: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}


@media all and (max-width: 800px) {
  .field_outer {
    
    width: 100%;
  
  }
}


@media all and (max-width: 800px) {
  .field_outer {
    
    padding-right: 6px;
  
  }
}



