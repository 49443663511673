.transparent_box {
    background-color: #0B0B0B;;
    position: absolute;
    height: 70px;
    width: 100%; 
    position: fixed;
    z-index: 150;
    display: flex;
    /* color: orange; */
}

@media screen and (min-width: 800px) {
    /* For mobile phones: */
    .transparent_box {
      display: none;
    }
  }

.hamburger {
    right: 20px;
    position: fixed;
    height: 30px;
    width: 30px;
    top: 20px;
    /* z-index:150; */
}

.logo {
  height: 40px;
  margin-left: 10px;
  /* margin-top: 10px; */
  align-self: center;
}